// all styles here
import "./src/assets/css/bootstrap.min.css";
import "./src/assets/css/animate.min.css";
import "./src/assets/css/boxicons.min.css";
import "./src/assets/css/flaticon.css";
import "./node_modules/react-modal-video/css/modal-video.min.css";
import "react-accessible-accordion/dist/fancy-example.css";
import "react-image-lightbox/style.css";
// import "./src/assets/css/style.css"
// import "./src/assets/css/responsive.css";
